body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* global variables */
:root {
  --theme-primary-text: black;
  --theme-secondary-text: grey;
  --theme-button-text: white;
  --theme-text: #c1a661;
  --theme-background: white;
  --theme-background-secondary: white;
  --theme-background-trim: #c8c8c8;
}

#search-container, #detail-container {
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 1200px) {
  #search-container {
    max-width: 2000px;
  }
}
@media (min-width: 992px) {
  #search-container {
    max-width: 970px;
  }
}
@media (min-width: 768px) {
  #search-container {
    max-width: 750px;
  }
}
@media (min-width: 749px) {
  #search-container {
    max-width: 748px;
  }
  #detail-container {
    max-width: 1048px;
  }
}
@media (min-width: 1200px) {
  #search-container {
    width: 1170px;
  }
}
@media (min-width: 992px) {
  #search-container {
    width: 970px;
  }
}
@media (min-width: 768px) {
  #search-container {
    width: 750px;
  }
}
